@tailwind base;
@tailwind components;
@tailwind utilities;
@import "./congratulation.css";

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.rouletteContainerCustom  > div {
  transform: rotate(-46.5deg);

  width: 85vw;
  height: 85vw;
  max-width: 100%;
  max-height: 100%;
}

.rouletteContainerDefault  > div {
  transform: rotate(-46.5deg);

  width: 85vw;
  height: 85vw;
  max-width: 100%;
  max-height: 100%;
}

.rouletteContainerCustom  > div:first-child {
  display: none;
}

.rouletteContainerDefault  > div:first-child {
  display: none;
}

@media only screen and (max-width: 768px) {
  .rouletteContainerDefault > div {  
    width: 85vw;
    height: 85vw;
  }
}

/* @media only screen and (max-width: 768px) {
  .rouletteContainerCustom > div {  
    width: 85vw;
    height: 85vw;
  }
} */
@keyframes rouletteMainBoxBtnShadow {
  0% {
    box-shadow: 0 0 16px rgba(0, 0, 0, 0.3), 0 2px 3px 1px rgba(0, 0, 0, 0.35),
      inset -1px 0 1px rgba(0, 0, 0, 0.5), inset 1px 1px 1px #fff;
  }
  to {
    box-shadow: 0 0 48px rgba(0, 0, 0, 0.9), 0 2px 3px 1px rgba(0, 0, 0, 0.35),
      inset -1px 0 1px rgba(0, 0, 0, 0.5), inset 1px 1px 1px #fff;
  }
}

.rouletteMain_box_btn {
  animation: rouletteMainBoxBtnShadow 0.5s infinite alternate both;
  background-position: 0 0;
  background-size: 200% 100%;
  border-radius: 50%;
  bottom: 0;
  box-shadow: 0 0 46px rgba(0, 0, 0, 0.75), 0 2px 3px 1px rgba(0, 0, 0, 0.35),
    inset -1px 0 1px rgba(0, 0, 0, 0.5), inset 1px 1px 1px #fff;
}

.ant-table-tbody {
  overflow-y: scroll !important;
  max-height: 100px !important;
}

.ant-table-cell {
  font-size: 18px;
}

.winners-list {
  margin-top: 20px;
}

/* Animation players */

.winner-item {
  transform: translateY(-100px);
  animation: dropIn 0.8s ease-out forwards;
}

/* Animation */
@keyframes dropIn {
  0% {
    opacity: 0;
    transform: translateY(-100px);
  }
  60% {
    opacity: 1;
    transform: translateY(10px);
  }
  80% {
    transform: translateY(-5px);
  }
  100% {
    transform: translateY(0);
  }
}

/* Custom Scroll */
.custom-scroll::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 4px;
}

.custom-scroll::-webkit-scrollbar {
  width: 5px;
}

.custom-scroll-none::-webkit-scrollbar {
  width: 0px;
}

.ant-dropdown-menu {
  max-height: 300px;
  overflow-y: auto;
}

.ant-dropdown-menu::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

.ant-dropdown-menu::-webkit-scrollbar {
  width: 10px;
}

.ant-dropdown-menu::-webkit-scrollbar-track {
  background: #ffffff;
}